import React, { useState } from 'react';
import { GiBigWave, GiWindSlap } from "react-icons/gi";
import { FiWind } from "react-icons/fi";
import { Tooltip } from 'react-tooltip'
import { LuWebcam } from "react-icons/lu";
import { FaRegImage } from "react-icons/fa";

const WeatherLayerCheckBox = ({ weatherCheckboxStatus, handleSettingsCheckBoxChange }) => {

    const [toolTipText, setToolTipText] = useState('');
    
    const iconSize = 28;
    const iconColor = 'white';

    const layerOptions = [
        {
            layer: 'waveHeight',
            icon: <GiBigWave size={iconSize} color={iconColor} />,
            toolTip: 'Wave Height'
        },
        {
            layer: 'windSpeed',
            icon: <FiWind size={iconSize} color={iconColor} />,
            toolTip: 'Wind Speed'
        },
        // {
        //     layer: 'windAnimation',
        //     icon: <GiWindSlap size={iconSize} color={iconColor} />,
        //     toolTip: 'Wind Animation'
        // },
        {
            layer: 'webCams',
            icon: <LuWebcam size={iconSize} color={iconColor} />,
            toolTip: 'Webcams'
        },
        {
            layer: 'photos',
            icon: <FaRegImage size={iconSize} color={iconColor} />,
            toolTip: 'User Photos'
        },
    ]

    return (

        <div className="checkbox-container">
            <Tooltip anchorSelect=".my-anchor-element" place="top">
                {toolTipText  }
            </Tooltip>
            {layerOptions.map((layerOption, index) => {

                return (
                    <label
                        onMouseOver={() => setToolTipText(layerOption.toolTip)}
                        // onMouseOut={handleHoverOut}
                        key={index}
                        className={`my-anchor-element map-checkbox-item ${weatherCheckboxStatus[layerOption.layer] === 'on' ? 'selected-map-checkbox' : ''}`}
                    >
                        {layerOption.icon}
                        <input
                            name={layerOption.layer}
                            type="checkbox"
                            className="toggle-checkbox"
                            checked={weatherCheckboxStatus[layerOption.layer] === 'on'}
                            onChange={handleSettingsCheckBoxChange}
                        />
                        {/* {hoveredCheckbox === 'webcams' && <span className='icon-description'>Webcams</span>} */}
                    </label>
                )
            }
            )}

        </div>

    )

}

export default WeatherLayerCheckBox;