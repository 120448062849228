

const WindSpeedChart = ({ forecastModel }) => {

  const valueArray = forecastModel === 'wind' ? [0, 5, 10, 15, 20, 25] : [1, 2, 3, 4, 6, 16, 25];

    return (

        <div className="wind-speed-bar">
        <div className="wind-speed-labels">
          {valueArray.map((value, index) => (
            <span key={index}>
              {value}
            </span>
          ))}
        </div>
        <div className="gradient-bar"></div>
      </div>

    )

}

export default WindSpeedChart;