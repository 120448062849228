import { useState, useContext, useEffect, useRef, useCallback } from 'react';
import * as React from 'react';
import ReactMapGl, { Marker, Popup, Source, Layer } from 'react-map-gl';
import { Context } from '../context/context.jsx';
import SidebarPro from '../global/sidebar';
import BottomNav from '../global/bottomNav';
import anchorageData from '../../destinationData/anchorageArray.geojson';
import filteredMoorageArray from '../../destinationData/moorageFiltered.geojson';
import anchorageArray from '../../destinationData/sportyAnchorages.jsx';
import dockArray from '../../destinationData/sportyDocks.jsx';
import buoyArray from '../../destinationData/sportyBuoys.jsx';
import fuelDockArray from '../../destinationData/fuelDocks.jsx';
import DestinationsLayerCheckBox from '../destinationsMap/destinationsLayerCheckbox.jsx';
import DestinationsPopupContent from '../destinationsMap/mapDestinationAnchoragePopup.jsx';
import DestinationsDockPopupContent from '../destinationsMap/mapDestinationDockPopup.jsx';
import DestinationsFuelDockPopupContent from '../destinationsMap/mapDestinationFuelDockPopup.jsx';
import SlidingSidebar from '../destinationsMap/slidingSidebar.jsx';
import DestinationLegendBar from '../destinationsMap/destinationLegendBar.jsx';
import DestinationsBuoyPopupContent from '../destinationsMap/mapDestinationMooringPopup.jsx';
import { MdAnchor } from "react-icons/md";

const DestinationsMap = () => {

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  // const API_BASE_URL = 'http://localhost:3847';
  const userLocation = useContext(Context).userLocation
  const MAPBOX_API_KEY = process.env.REACT_APP_MAPBOX_API_KEY;
  const mapRef = useRef(null);

    const [zoomLevel, setZoomLevel] = useState(8);
    const [mobile, setMobile] = useState(window.innerWidth < 600);
    const [popupInfo, setPopupInfo] = useState(null);
    const [selectedAnchorage, setSelectedAnchorage] = useState(null);
    const [selectedDock, setSelectedDock] = useState(null);
    const [selectedFuelDock, setSelectedFuelDock] = useState(null);
    const [selectedBuoy, setSelectedBuoy] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [searchFilters, setSearchFilters] = useState({
        bottomType: [],
        dinghyLanding: false,
        hiking: false,
        camping: false,
        beaches: false,
        });
    const [destinationsCheckboxStatus, setDestinationsCheckboxStatus] = useState({
        anchorage: 'on',
        governmentAnchorage: 'on',
        publicDock: 'on',
        mooringBuoy: 'on',
      });
    const [viewport, setViewport] = useState({
    latitude: 49.4,
    longitude: -123.4,
    zoom: zoomLevel,
    });

    const handleMapLayer = () => {

        const map = mapRef.current;
        let visibility = map.getLayoutProperty('anchorages-layer', 'visibility');
    
        if (visibility === undefined) {
            // Set the initial visibility to 'none' or 'visible' depending on your default
            visibility = 'visible';
            map.setLayoutProperty('anchorages-layer', 'visibility', visibility);
        }
    
        if (visibility === 'visible') {
          map.setLayoutProperty('anchorages-layer', 'visibility', 'none');
        } else {
          map.setLayoutProperty('anchorages-layer', 'visibility', 'visible');
      }
    }

    const handleMapLoad = useCallback((event) => {
        const map = event.target;
        mapRef.current = map;
    }, []);

    // Set users location to center of map
    useEffect(() => {
        if(userLocation.lat > 0) {
        viewport.latitude = userLocation.lat;
        viewport.longitude = userLocation.lng;
        }
    })
        
    // Close the popup
    const handleClosePopup = () => {
        setSelectedAnchorage(null);
        setSelectedDock(null);
        setSelectedFuelDock(null);
        setPopupInfo(null);
        setSelectedBuoy(null);
    };

    const handleSettingsCheckBoxChange = (event) => {
        const { name, checked } = event.target;
        console.log("name", name);
        if(name === 'governmentAnchorage') handleMapLayer();
        // localStorage.setItem('userSettings', JSON.stringify(response.data[0]));
        setDestinationsCheckboxStatus((prevSettings) => ({
          ...prevSettings,
          [name]: checked ? 'on' : 'off'
        }));
      };

    // Handle click on the layer to show popup - this is for gov anchorages added using geojson layer
    const handleGeoJsonClick = (event) => {
        setPopupInfo(null);
        const feature = event.features[0];
        if (feature) {
            setTimeout(() => {
                feature.properties.NAME.length > 2 
                ? setPopupInfo({
                    name: feature.properties.NAME,
                    coordinates: feature.geometry.coordinates
                }) : feature.properties.LOCATION.length > 2 ? setPopupInfo({
                    name: feature.properties.LOCATION,
                    coordinates: feature.geometry.coordinates
                }) :
                setPopupInfo({
                    name: "No Name",
                    coordinates: feature.geometry.coordinates
                });
            }, 50);
        }
    }

    const filterArray = (type, filters, array) => {
        const shouldInclude = (filterValue, itemValue) => {
          // If filterValue is false, include all (ignore filtering)
          return !filterValue || itemValue === filterValue;
        };
      
        if (type === "anchorage") {
          return array.filter(item => {
            const bottomTypeMatch = Array.isArray(filters.bottomType)
              ? filters.bottomType.includes(item.anchoring.bottomType)
              : filters.bottomType === item.anchoring.bottomType;
      
            return (
              bottomTypeMatch &&
              shouldInclude(filters.dinghyLanding, item.features.dinghyLanding) &&
              shouldInclude(filters.hiking, item.features.hiking) &&
              shouldInclude(filters.camping, item.features.camping) &&
              shouldInclude(filters.beaches, item.features.beaches)
            );
          });
        } else if (type === "publicDock") {
          return array.filter(item => {
            return (
              shouldInclude(filters.hiking, item.features.hiking) &&
              shouldInclude(filters.camping, item.features.camping) &&
              shouldInclude(filters.beaches, item.features.beaches)
            );
          });
        } else if (type === "mooringBuoy") {
            return array.filter(item => {
                return (
                shouldInclude(filters.hiking, item.features.hiking) &&
                shouldInclude(filters.camping, item.features.camping) &&
                shouldInclude(filters.beaches, item.features.beaches)
                );
            });
        } else if (type === "fuelDock") {
            return array.filter(item => {
                return (
                shouldInclude(filters.hiking, item.features.hiking) &&
                shouldInclude(filters.camping, item.features.camping) &&
                shouldInclude(filters.beaches, item.features.beaches)
                );
            });
        }
    };

    // Function to handle the search request
    const handleSearch = (type, searchFilters) => {
        let filteredResults = [];

        setSearchFilters((prevFilters) => ({ ...prevFilters, type }));

        if (type === "anchorage") {
        filteredResults = filterArray(type, searchFilters, anchorageArray);
        } else if (type === "publicDock") {
        filteredResults = filterArray(type, searchFilters, dockArray);
        } else if (type === "mooringBuoy") {
        filteredResults = filterArray(type, searchFilters, buoyArray);
        } else if (type === "fuelDock") {
        filteredResults = filterArray(type, searchFilters, fuelDockArray);
        }
        setFilteredData(filteredResults);
        console.log("filteredResults", filteredResults);
    };

    // function to reset the search filters
    const resetSearchFilters = () => {
        setSearchFilters({
            bottomType: [],
            dinghyLanding: false,
            hiking: false,
            camping: false,
            beaches: false,
        });
    };

  return (
  <ReactMapGl
    initialViewState={viewport}
    mapboxAccessToken={MAPBOX_API_KEY}
    maxZoom={15}
    minZoom={4.5}
    onZoom={(e) => { 
      setZoomLevel(e.viewState.zoom)
      console.log(e.viewState.zoom)
      }}
    style={{width: '100vw', height: '100vh'}}
    mapStyle="mapbox://styles/jcon12/cltis4ylt011e01pta8nv4jl6"
    transitionDuration={200}
    ref={mapRef}
    onLoad={handleMapLoad}
    onClick={handleGeoJsonClick}
    interactiveLayerIds={['anchorages-layer']} // Use correct layer IDs

  >
    {searchFilters.type ? null : (
    <Source id="anchorages" type="geojson" data={anchorageData}>
        <Layer
          id="anchorages-layer"
          type="circle"
          paint={{
            'circle-radius': 4.5,
            'circle-color': 'blue',
            "circle-stroke-color": "white",
            "circle-stroke-width": 1,
          }}
        />
    </Source>
    )}
    {mobile ? <BottomNav /> : <SidebarPro />} 
    <SlidingSidebar handleSettingsCheckBoxChange={handleSettingsCheckBoxChange} handleSearch={handleSearch} resetSearchFilters={resetSearchFilters} />
    {destinationsCheckboxStatus.anchorage === 'on' && (

    searchFilters.type === 'publicDock' ? (
    // If searching for public docks, show only filtered docks
    filteredData.map((dock, index) => (
        <Marker
            key={index}
            latitude={dock.coordinates.latitude}
            longitude={dock.coordinates.longitude}
        >
            <div
                className="marker-circle"
                style={{
                    backgroundColor: 'rgb(255, 145, 0)', // Orange for filtered docks
                    width: '8px',
                    height: '8px',
                }}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setSelectedDock(dock);
                }}
            />
        </Marker>
    ))
) : searchFilters.type === 'anchorage' ? (
    // If searching for anchorages, show only filtered anchorages
    filteredData.map((anchorage, index) => (
        <Marker
            key={index}
            latitude={anchorage.coordinates.latitude}
            longitude={anchorage.coordinates.longitude}
        >
            <div
                className="marker-circle"
                style={{
                    backgroundColor: 'rgb(218, 38, 237)',
                    width: '8px',
                    height: '8px',
                }}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setSelectedAnchorage(anchorage);
                }}
            />
        </Marker>
    ))
) : searchFilters.type === 'mooringBuoy' ? (
    // If searching for mooringBuoy, show only filtered mooringBuoys
    filteredData.map((buoy, index) => (
        <Marker
            key={index}
            latitude={buoy.coordinates.latitude}
            longitude={buoy.coordinates.longitude}
        >
            <div
                className="marker-circle"
                style={{
                    backgroundColor: 'red',
                    width: '8px',
                    height: '8px',
                }}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setSelectedBuoy(buoy);
                }}
            />
        </Marker>
    ))
    ) : searchFilters.type === 'fuelDock' ? (
    // If searching for fuelDock, show only filtered fuelDocks
    filteredData.map((dock, index) => (
        <Marker
            key={index}
            latitude={dock.coordinates.latitude}
            longitude={dock.coordinates.longitude}
        >
            <div
                className="marker-circle"
                style={{
                    backgroundColor: 'black',
                    width: '8px',
                    height: '8px',
                }}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setSelectedFuelDock(dock);
                }}
            />
        </Marker>
    ))
) : (
    // On initial load, show both anchorages and docks
    <>
        {anchorageArray.map((anchorage, index) => (
            <Marker
                key={index}
                latitude={anchorage.coordinates.latitude}
                longitude={anchorage.coordinates.longitude}
            >
                <div
                    className="marker-circle"
                    style={{
                        backgroundColor: 'rgb(218, 38, 237)',
                        width: '8px',
                        height: '8px',
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSelectedAnchorage(anchorage);
                    }}
                />
            </Marker>
        ))}
        {dockArray.map((dock, index) => (
            <Marker
                key={index}
                latitude={dock.coordinates.latitude}
                longitude={dock.coordinates.longitude}
            >
                <div
                    className="marker-circle"
                    style={{
                        backgroundColor: 'rgb(255, 145, 0)', // Orange for docks
                        width: '8px',
                        height: '8px',
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSelectedDock(dock);
                    }}
                />
            </Marker>
        ))}
        {buoyArray.map((buoy, index) => (
                <Marker
                    key={index}
                    latitude={buoy.coordinates.latitude}
                    longitude={buoy.coordinates.longitude}
                >
                    <div
                        className="marker-circle"
                        style={{
                            backgroundColor: 'red',
                            width: '8px',
                            height: '8px',
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setSelectedBuoy(buoy);
                        }}
                    />
                </Marker>
            ))
        }
        {fuelDockArray.map((dock, index) => (
            <Marker
                key={index}
                latitude={dock.coordinates.latitude}
                longitude={dock.coordinates.longitude}
            >
                <div
                    className="marker-circle"
                    style={{
                        backgroundColor: 'black',
                        width: '8px',
                        height: '8px',
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSelectedFuelDock(dock);
                    }}
                />
            </Marker>
        ))}
    </>
)


)}

{/* Conditionally render markers for public docks */}
{destinationsCheckboxStatus.publicDock === 'on' && (
    searchFilters.type === 'publicDock' ? (
        // Show only filtered docks when the user is searching for docks
        filteredData.map((dock, index) => (
            <Marker
                key={index}
                latitude={dock.coordinates.latitude}
                longitude={dock.coordinates.longitude}
            >
                <div
                    className="marker-circle"
                    style={{
                        backgroundColor: 'rgb(255, 145, 0)',
                        width: '8px',
                        height: '8px',
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSelectedDock(dock);
                    }}
                />
            </Marker>
        ))
    ) ? searchFilters.type !== 'publicDock' :
    (
        // Show all docks if not searching for them
        dockArray.map((dock, index) => (
            <Marker
                key={index}
                latitude={dock.coordinates.latitude}
                longitude={dock.coordinates.longitude}
            >
                <div
                    className="marker-circle"
                    style={{
                        backgroundColor: 'rgb(255, 145, 0)',
                        width: '8px',
                        height: '8px',
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSelectedDock(dock);
                    }}
                />
            </Marker>
        ))
    ) : null
)}
    {selectedAnchorage ? (
        <Popup
            latitude={selectedAnchorage.coordinates.latitude}
            longitude={selectedAnchorage.coordinates.longitude}
            onClose={handleClosePopup}
        >
            <DestinationsPopupContent selectedAnchorage={selectedAnchorage} />
        </Popup>
    ) : null}
    {selectedDock ? (
        <Popup
            latitude={selectedDock.coordinates.latitude}
            longitude={selectedDock.coordinates.longitude}
            onClose={handleClosePopup}
        >
            <DestinationsDockPopupContent selectedDock={selectedDock} />
        </Popup>
    ) : null}
    {selectedFuelDock ? (
        <Popup
            latitude={selectedFuelDock.coordinates.latitude}
            longitude={selectedFuelDock.coordinates.longitude}
            onClose={handleClosePopup}
        >
            <DestinationsFuelDockPopupContent selectedFuelDock={selectedFuelDock} />
        </Popup>
    ) : null}
    {selectedBuoy ? (
        <Popup
            latitude={selectedBuoy.coordinates.latitude}
            longitude={selectedBuoy.coordinates.longitude}
            onClose={handleClosePopup}
        >
            <DestinationsBuoyPopupContent selectedBuoy={selectedBuoy} />
        </Popup>
    ) : null}
    {popupInfo && (
        <Popup
          longitude={popupInfo.coordinates[0]}
          latitude={popupInfo.coordinates[1]}
          anchor="top"
          onClose={handleClosePopup}
        >
            <div className='gov-anchorage-popup'>
                <div style={{fontWeight: 'bold', marginRight: 10}}>{popupInfo.name}</div>
                <MdAnchor size={20} />
            </div>
        </Popup>
      )}
    <DestinationLegendBar />
        {/* <DestinationsLayerCheckBox
            destinationsCheckboxStatus={destinationsCheckboxStatus}
            handleSettingsCheckBoxChange={handleSettingsCheckBoxChange}
            handleMapLayer={handleMapLayer}
        /> */}
    </ReactMapGl>
  )
}

export default DestinationsMap;