import React from 'react';

const WindSpeedWindow = ({ forecastValue, windDirection, forecastType }) => {

  const getWindDirection = (degrees) => {
    // Define compass directions in 16 segments
    const directions = [
      "N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE",
      "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"
    ];
  
    // Calculate the index by dividing degrees by 22.5 (360°/16 = 22.5° per segment)
    
    const index = Math.round(degrees / 22.5) % 16;
  
    // Return the corresponding direction
    return directions[index];
  }
  // Determine units based on forecastType
  const units = forecastType === 'wave' ? 'ft' : 'mph';

  return (
    
    isNaN(forecastValue) ? null :
    <div className="wind-speed-window">      
      <p>{forecastValue} {units}</p>
      <p>{getWindDirection(windDirection)}</p>
    </div>
  );
};

export default WindSpeedWindow;
